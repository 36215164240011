
import { ellipse, square, triangle, peopleCircleOutline, homeOutline, listOutline } from 'ionicons/icons';

export default {
	components: {  },
	setup() {
		return {
			ellipse, square, triangle, peopleCircleOutline, homeOutline, listOutline
		}
	}
}

import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
 
export default new Vuex.Store({
    state: {
        //watching order premenne
        movies: [],
        upcomingMovies: [],
        showMovies: true,
        showSeries: true,
        showMarvelHeroes: true,
        showDefendersSaga: true,
        showSpecials: true,

        //database premenne
        databaseEntries: [],
        showHumans: true,
        showKree: true,
        showSkrulls: true,
        showAsgardians: true,
        showMutants: true,
        showInhumans: true,
        showFrostGiants: true,
        showCelestials: true,
        showEternals: true,
        showCosmicEntities: true,
        showTitans: true,
	},

    getters: {},
    mutations: {
        updateMovies(state, movies) {
            state.movies = movies
        },

        updateUpcomingMovies(state, upcomingMovies) {
            state.upcomingMovies = upcomingMovies
        },

        updateDatabaseEntries(state, databaseEntries) {
            state.databaseEntries = databaseEntries
        },
 

        changeMoviesState(state, value) {
            state.showMovies = value 
        },
        changeSeriesState(state, value) {
            state.showSeries = value 
        },
        changeMarvelHeroesState(state, value) {
            state.showMarvelHeroes = value 
        },
        changeDefendersSagaState(state, value) {
            state.showDefendersSaga = value 
        },
        changeSpecialsState(state, value) {
            state.showSpecials = value
        },


        changeHumansState(state, value) {
            state.showHumans = value 
        },
        changeKreeState(state, value) {
            state.showKree = value 
        },
        changeSkrullsState(state, value) {
            state.showSkrulls = value 
        },
        changeAsgardiansState(state, value) {
            state.showAsgardians = value 
        },
        changeMutantsState(state, value) {
            state.showMutants = value 
        },
        changeInhumansState(state, value) {
            state.showInhumans = value 
        },
        changeFrostGiantsState(state, value) {
            state.showFrostGiants = value 
        },
        changeCelestialsState(state, value) {
            state.showCelestials = value 
        },
        changeEternalsState(state, value) {
            state.showEternals = value 
        },
        changeCosmicEntitiesState(state, value) {
            state.showCosmicEntities = value 
        },
        changeTitansState(state, value) {
            state.showTitans = value 
        },
    },
    actions: {}
});
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Footer from '../views/footer/Footer.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/watching-order'
  },
  {
    path: '',
    component: Footer,
    children: [
      {
        path: '',
        redirect: '/watching-order'
      },
      {
        path: 'timeline',
        component: () => import('@/views/timeline/Timeline.vue')
      },
      {
        path: 'watching-order',
        component: () => import('@/views/watching-order/Watching-order.vue')
      },
      {
        path: 'database',
        component: () => import('@/views/database/Database.vue')
      },
      {
        name: 'movie-details',
        path: '/movie-details/:id',
        component: () => import('@/views/watching-order/components/movie/Movie-details.vue')
      },
      {
        name: 'database-details',
        path: '/database-details/:id',
        component: () => import('@/views/database/components/details/Database-details.vue')
      },
      {
        name: 'season-details',
        path: '/season-details/:id',
        component: () => import('@/views/watching-order/components/Season-details.vue')
      },
      {
        name: 'episode-details',
        path: '/shows/:show_id/episodes/:episode_id',
        component: () => import('@/views/watching-order/components/Episode-details.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
